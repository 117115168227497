<template>
  <span>
    <slot></slot>
    <el-dialog
      :before-close="handleClose"
      :close-on-click-modal="false"
      :title="payTitile"
      :visible.sync="dialogVisible"
      width="530px"
    >
      <div class="codeurl-wrap">
        <p class="amount">支付金额：¥{{ postData.tradeAmount }}</p>
        <el-button :loading="!codeUrl" style="width: 160px; height: 80px" v-if="!codeUrl"
          >加载中...</el-button
        >
        <img :src="codeUrl" alt class="code" />
        <div class="tips" v-if="codeUrl">
          <p>请在<span class="red">30分钟</span>内完成支付</p>
          <p>支付完成后请完成点击付款完成。</p>
          <el-button :loading="changeLoading" @click="changePayType" type="text"
            >切换{{ otherPay }}支付</el-button
          >
        </div>
      </div>
      <div class="dialog__footer" slot="footer">
        <slot name="sure">
          <el-button @click="sure" type="primary" v-if="codeUrl" class="dialog__footer--btn"
            >付款完成</el-button
          >
        </slot>
      </div>
    </el-dialog>
  </span>
</template>

<script>
import { wxNative, qrCode, qrCodeNew } from '@/api/pay';
import QRCode from 'qrcode';
export default {
  name: 'PayCode',
  props: {
    // 支付接口传参
    postData: {
      type: Object,
      default() {
        return {
          payBizCode: '',
          bizType: '',
          body: '',
          tenantTradeNo: '',
          tradeAmount: '',
          payType: '', // 支付方式
        };
      },
    },
  },
  data() {
    return {
      dialogVisible: false,
      changeLoading: false,
      codeUrl: '', // 支付二维码
    };
  },
  computed: {
    payTitile() {
      const payType = this.postData.payType === 'weChat' ? '微信' : '支付宝';
      return `请用${payType}扫码支付`;
    },
    otherPay() {
      const payType = this.postData.payType === 'weChat' ? '支付宝' : '微信';
      return payType;
    },
  },
  methods: {
    // 修改支付方式
    changePayType() {
      this.changeLoading = true;
      this.codeUrl = '';
      localStorage.setItem('otherPay', this.otherPay);
      this.$emit('changePayType', this.otherPay);
    },
    // 关闭弹窗
    handleClose() {
      this.codeUrl = '';
      this.dialogVisible = false;
    },
    // 展示弹窗
    show() {
      if (this.disabled) return;
      if (this.postData.tradeAmount && this.postData.payType) {
        this.dialogVisible = true;
        this.submit();
        return;
      }
      this.$message.error('请选择支付方式或输入金额');
    },
    // 支付完成
    sure() {
      // 用户点击充值完成按钮，父页面进行刷新
      this.$emit('sureRefresh');
      this.handleClose();
    },
    // 支付
    async submit() {
      if (this.postData.returnUrl) {
        qrCodeNew(this.postData).then((response) => {
          const data = JSON.parse(response.data);
          if (response.code === '0') {
            this.codeUrl = data.qrCodeUrl;
            this.changeLoading = false;
            return;
          }
          this.$message.error(response.msg);
        });
        return;
      }
      const request = this.postData.payType === 'weChat' ? wxNative : qrCode;
      request(this.postData)
        .then((response) => {
          const codeUrl =
            this.postData.payType === 'weChat' ? response.data.codeUrl : response.data;
          if (response.code === '0') {
            QRCode.toDataURL(codeUrl).then((url) => {
              this.codeUrl = url;
            });
            this.changeLoading = false;
            return;
          }
          this.$message.error(response.msg);
        })
        .finally(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-dialog__title {
  font-size: 16px;
  color: #222;
  line-height: 22px;
}
/deep/ .el-dialog__body {
  padding-bottom: 10px;
}
.codeurl-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.code {
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
}
.amount {
  font-size: 12px;
  margin: 0;
  padding: 0;
  color: #AB0033;
}
.tips {
  text-align: center;
  font-size: 12px;
  p {
    &:first-of-type {
      margin-bottom: 10px;
    }
  }
}
.dialog__footer {
  text-align: center;
  &--btn {
    width: 130px;
    height: 30px;
    padding: 0;
  }
}
.red {
  color: #AB0033;
}
</style>
